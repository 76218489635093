import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About.vue')
  },
  {
    path: '/suppliers',
    name: 'Category & Supplier Search',
    component: () => import('@/views/Suppliers.vue')
  },
  {
    path: '/build-party',
    name: 'BuildYourParty',
    component: () => import('@/views/BuildYourParty.vue')
  },
  {
    path: '/checkout/:identifier',
    name: 'Checkout',
    component: () => import('@/views/Checkout.vue')
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('@/views/Account.vue'),
    children: [
      {
        path: '',
        redirect: '/account/details'
      },
      {
        path: 'details',
        component: () => import('@/views/AccountDetails.vue')
      },
      {
        path: 'my-parties',
        component: () => import('@/views/MyParties.vue'),
      },
      {
        path: 'edit-party/:id',
        component: () => import('@/views/EditParty.vue')
      },
      {
        path: 'my-calendar',
        component: () => import('@/views/MyCalendar.vue')
      },
    ]
  },
  {
    path: '/invitation/:code',
    name: 'Invitation',
    component: () => import('@/views/Invitation.vue')
  },
  {
    path: '/sign-in',
    name: 'Sign In',
    component: () => import('@/views/Signin.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/Register.vue')
  },
  {
    path: '/reset-password',
    name: 'Forgotten Password',
    component: () => import('@/views/ForgottenPassword.vue')
  },
  {
    path: '/success/:identifier?',
    name: 'Success',
    component: () => import('@/views/Success.vue')
  },
  {
    path: '/failure',
    name: 'Failure',
    component: () => import('@/views/Failure.vue')
  },
  {
    path: '/privacy-policy-customer',
    name: 'Privacy Policy - Customer',
    component: () => import('@/views/PrivacyPolicyCustomer.vue'),
  },
  {
    path: '/privacy-policy-supplier',
    name: 'Privacy Policy - Supplier',
    component: () => import('@/views/PrivacyPolicySupplier.vue'),
  },
  {
    path: '/terms-of-business-customer',
    name: 'Terms of Business - Customer',
    component: () => import('@/views/TermsOfBusinessCustomer.vue'),
  },
  {
    path: '/terms-of-business-supplier',
    name: 'Terms of Business - Supplier',
    component: () => import('@/views/TermsOfBusinessSupplier.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
