import { ActionTree } from 'vuex';
import { State } from './state';
import { FacebookLogin } from "@capacitor-community/facebook-login";
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth'
import axios from 'axios';
import { VueCookieNext } from 'vue-cookie-next'

// console.log("env test");
// console.log(process.env.VUE_APP_API_URL);

GoogleAuth.init()

// TODO: Redirect to register if attempted social login fails

export const actions: ActionTree<State, any> = {
    async loginFacebook(store) {
        const FACEBOOK_PERMISSIONS = ["email", "public_profile"];
        const facebookLoginAttempt = await FacebookLogin.login({
            permissions: FACEBOOK_PERMISSIONS,
        });

        if (facebookLoginAttempt.accessToken && facebookLoginAttempt.accessToken.token !== null) {
            // Login successful.
            // console.log(`Facebook Login Successful`);

            // Request user details from facebook
            const userProfile = await FacebookLogin.getProfile<{
                email: string;
                first_name: string;
                last_name: string;
                birthday: string;
                id: string;
                picture: { data: any };
            }>({ fields: ["email", "first_name", "last_name", "picture"] });

            // Console log the results
            // console.log(`Facebook user's details are `);
            // console.log(userProfile);

            // Setup data variable for user check and form fields
            const data = {
                name: userProfile.first_name + " " + userProfile.last_name,
                email: userProfile.email,
                facebookId: userProfile.id,
                profileImage: userProfile.picture.data.url,
            }

            // Send to API to check if exists
            try {
                const config = {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                };
                // Call API
                const exists = await axios.post(process.env.VUE_APP_API_URL + "/api/check-user-exists", data, config)

                // Check if user already exists
                if (typeof exists.data.data.exists !== "undefined" && exists.data.data.exists === true) {
                    // console.log('exists = true')
                    return "exists"
                }

                // If user doesn't exist then pre fill form fields
                if (typeof exists.data.data.exists !== "undefined" && exists.data.data.exists === false) {
                    // console.log('exists = false')
                    store.commit("registerForm", data)
                    return "signUp"
                }

                // Check if user is being logged in
                if (exists.data.data.token) {
                    store.commit("resetRegisterForm")
                    localStorage.setItem('token', exists.data.data.token)
                    store.dispatch("checkAuth")
                    return "login";
                }

                // Console log API results
                // console.log('exists')
                // console.log(exists)
                return exists;
            }
            catch (error) {
                console.log('catch exists')
                console.log(error)
            }
        } else {
            // Commit error message
            console.log("login failed");
            console.log(facebookLoginAttempt);
        }
    },
    async loginGoogle(store) {
        try {
            const userProfile = await GoogleAuth.signIn()
            // console.log(userProfile)
            if (userProfile.authentication && userProfile.authentication.idToken) {

                // Setup data variable for user check and form fields
                const data = {
                    name: userProfile.name,
                    email: userProfile.email,
                    googleId: userProfile.id,
                    profileImage: userProfile.imageUrl,
                }

                // Send to API to check if exists
                try {
                    const config = {
                        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                    };
                    // Call API
                    const exists = await axios.post(process.env.VUE_APP_API_URL + "/api/check-user-exists", data, config)

                    // Check if user exists
                    if (typeof exists.data.data.exists !== "undefined" && exists.data.data.exists === true) {
                        // console.log('exists = true')
                    }
                    // If user doesn't exist then pre fill form fields
                    if (typeof exists.data.data.exists !== "undefined" && exists.data.data.exists === false) {
                        // console.log('exists = false')
                        store.commit("registerForm", data)
                        return "signUp"
                    }
                    // Check if user is being logged in
                    if (exists.data.data.token) {
                        store.commit("resetRegisterForm")
                        localStorage.setItem('token', exists.data.data.token)
                        store.dispatch("checkAuth")
                        return "login";
                    }

                    // Console log API results
                    // console.log('exists')
                    // console.log(exists)
                    return exists;
                }
                catch (error) {
                    console.log('catch exists')
                    console.log(error)
                }

            }
        } catch (error) {
            console.log('catch google sign in error')
            console.log(error)
        }
    },
    async createAccount(store: any) {
        try {
            // Call API
            const res = await axios.post(process.env.VUE_APP_API_URL + "/api/auth/register", store.state.registerForm)
            // Check if user is being logged in
            if (res.data.data.token) {
                localStorage.setItem('token', res.data.data.token)
                store.dispatch("checkAuth")
                return "login";
            }
        } catch (error) {
            // eslint-disable-next-line
            return error.response.data
        }
    },
    async signIn(store, credentials) {
        // console.log('remeber cookie stored')
        // console.log(VueCookieNext.getCookie('pp_remember'))
        try {
            // Check remember cookie
            const rememberCookie = VueCookieNext.getCookie('pp_remember')
            if (rememberCookie) {
                credentials.rememberCookie = rememberCookie
            }

            // Call API
            const res = await axios.post(process.env.VUE_APP_API_URL + "/api/auth/login", credentials)

            // Check if we need 2fa
            if (res.data.data.twoFactor) {
                return "twoFactor";
            }

            // Check if user is being logged in
            if (res.data.data.remember_token !== null) {
                // console.log("Remember")
                // console.log(res.data)
                VueCookieNext.setCookie('pp_remember', res.data.data.remember_token, {
                    expire: '30d',
                    path: '/',
                    domain: '',
                    secure: 'true',
                })

            }
            if (res.data.data.token) {
                localStorage.setItem('token', res.data.data.token)
                store.dispatch("checkAuth")
                return "login";
            }
        } catch (error) {
            console.log('login error')
            console.log(error)
            return error
        }
    },
    async checkAuth(store) {
        try {
            // Call API
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            };
            const res = await axios.get(process.env.VUE_APP_API_URL + "/api/me", config);
            store.commit('updateUser', res.data)
        } catch (error) {
            // eslint-disable-next-line
            if (error.response.status === 401) {
                localStorage.removeItem('token')
                store.commit('updateUser')
            }
            // eslint-disable-next-line
            return error.response && error.response.data || error
        }
    },
    async logout(store) {
        try {
            // Call API
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            };
            const res = await axios.post(process.env.VUE_APP_API_URL + "/api/auth/logout", null, config);
            // Check if user is being logged in
            if (res.data.message == "Tokens Revoked") {
                localStorage.removeItem('token')
                store.commit('updateUser')
            }
        } catch (error) {
            // eslint-disable-next-line
            return error.response.data
        }
    },
    async getSupplierCategories(store) {
        try {
            const res = await axios.get(process.env.VUE_APP_API_URL + "/api/supplier-categories");
            // Check if user is being logged in
            store.commit('setCategories', res.data)
        } catch (error) {
            // eslint-disable-next-line
            // console.log(error.response)
            // eslint-disable-next-line
            return error.response.data
        }
    },
    async getSuppliers(store) {
        try {
            const res = await axios.get(process.env.VUE_APP_API_URL + "/api/suppliers");
            store.commit('setSuppliers', res.data)
        } catch (error) {
            // eslint-disable-next-line
            // console.log(error.response)
            // eslint-disable-next-line
            return error.response.data
        }
    },
    addToPartyBag(store, data) {
        store.commit('setAddToPartyBag', data)
    },
    removeFromPartyBag(store, data) {
        store.commit('setRemoveFromPartyBag', data)
    },
    async confirmParty(store) {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        };
        const res = await axios.post(process.env.VUE_APP_API_URL + "/api/confirm-party", store.state.partyBag, config);
        if (res.status === 200) {
            // Clear PartyBag data
            store.commit("clearPartyBag");
            return res;
        } else {
            console.log("error confirming party")
            console.log(res)
            return res;
        }
    },
    async updateParty(store, data) {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        };
        const res = await axios.post(process.env.VUE_APP_API_URL + "/api/update-party", data, config);
        if (res.status === 200) {
            store.dispatch("checkAuth");
            return 200
        }
    },
    async updateSupplierStatus(store, data) {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        };
        const res = await axios.post(process.env.VUE_APP_API_URL + "/api/update-supplier-status", data, config);
        if (res.status === 200) {
            store.dispatch("checkAuth");
            return 200
        }
    },
    async deleteParty(store, data) {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        };
        const res = await axios.post(process.env.VUE_APP_API_URL + "/api/delete-party", data, config);
        if (res.status === 200) {
            store.dispatch("checkAuth");
        }
    },
    async updateUser(store) {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        };
        const res = await axios.post(process.env.VUE_APP_API_URL + "/api/update-user", store.state.user, config);
        if (res.status === 200) {
            store.dispatch("checkAuth");
            return 200;
        }
    },
    async changePassword(store, data) {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        };
        const res = await axios.post(process.env.VUE_APP_API_URL + "/api/change-password", data, config);
        if (res.status === 200) {
            return 200;
        }
    },
    async createMyEvent(store, data) {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        };
        const res = await axios.post(process.env.VUE_APP_API_URL + "/api/create-my-event", data, config);
        if (res.status === 200) {
            store.dispatch("checkAuth");
            return 200;
        }
    },
    async deleteMyEvent(store, data) {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        };
        const res = await axios.post(process.env.VUE_APP_API_URL + "/api/delete-my-event", data, config);
        if (res.status === 200) {
            store.dispatch("checkAuth");
        }
    },
    async sendFeedback(store, data) {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        };
        const res = await axios.post(process.env.VUE_APP_API_URL + "/api/send-feedback", data, config);
        if (res.status === 200) {
            return 200;
        }
    },
    async sendAddressForPartyPack(store, data) {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        };
        const res = await axios.post(process.env.VUE_APP_API_URL + "/api/send-address-party-pack", data, config);
        if (res.status === 200) {
            return 200;
        }
    },
    async sendPaypalResponse(store, data) {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        };
        const res = await axios.post(process.env.VUE_APP_API_URL + "/api/send-paypal-response", data, config);
        if (res.status === 200) {
            return res;
        }
    },
    async getPaylinkToken(store, data) {
        return await axios.get(process.env.VUE_APP_API_URL + "/api/create-token-request/" + data);
    },
    async checkPayment(store, data) {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        };
        return await axios.get(process.env.VUE_APP_API_URL + "/api/check-payment/" + data, config);
    },
    async verifyTwoFactor(store, data) {
        const res = await axios.post(process.env.VUE_APP_API_URL + "/api/verify-two-factor", data);

        // console.log("Remember")
        // console.log(res.data)
        // Check if user is being logged in
        if (res.data.data.remember_token !== null) {
            VueCookieNext.setCookie('pp_remember', res.data.data.remember_token, {
                expire: '30d',
                path: '/',
                domain: '',
                secure: '',
            })
        }
        if (res.data.data.token) {
            localStorage.setItem('token', res.data.data.token)
            store.dispatch("checkAuth")
            return "login";
        } else {
            return res
        }
    },
}