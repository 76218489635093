
import {
  IonContent,
  IonPage,
  IonSlides,
  IonSlide,
  IonGrid,
  IonCol,
  IonRow,
  IonButton,
} from "@ionic/vue";
import { defineComponent } from "vue";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";

export default defineComponent({
  name: "Home",
  components: {
    IonContent,
    IonPage,
    IonSlides,
    IonSlide,
    IonGrid,
    IonRow,
    IonCol,
    Header,
    Footer,
    IonButton,
  },
  setup() {
    // Optional parameters to pass to the swiper instance. See http://idangero.us/swiper/api/ for valid options.
    const slideOpts = {
      slidesPerView: 1,
      initialSlide: 1,
      speed: 400,
      spaceBetween: 0,
      snap: true,
      autoplay: true,
      loop: true,
    };
    return { slideOpts };
  },
});
