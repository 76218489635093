import { MutationTree } from 'vuex';
import { State } from './state';

export const mutations: MutationTree<State> = {
  updateUser(state, value = null) {
    state.user = value
  },
  registerForm(state, value) {
    const currentState = state.registerForm
    state.registerForm = { ...currentState, ...value }
  },
  resetRegisterForm(state) {
    state.registerForm = {
      name: "",
      email: "",
      phone: "",
      dob: "",
      password: "",
      facebookId: "",
      googleId: "",
      profileImage: "",
    }
  },
  setCategories(state, value) {
    state.categories = value
  },
  setSuppliers(state, value) {
    state.suppliers = value
  },
  setAddToPartyBag(state, value) {
    state.partyBag.suppliers.push(value)
  },
  setRemoveFromPartyBag(state, value) {
    state.partyBag.suppliers = state.partyBag.suppliers.filter((sup: any) => {
      return sup.id !== value
    })
  },
  clearPartyBag(state) {
    state.partyBag = {
      partyName: "",
      dateTime: "",
      guests: 0,
      children: 0,
      locationAddress: "",
      locationCoords: {
        lat: "",
        lon: "",
      },
      image: "",
      userId: 0,
      bookingStatus: "",
      additionalRequirements: "",
      suppliers: []
    }
  },
  setCategoryFilter(state, catId) {
    state.searchbar.category = catId;
  },
  setPartyBag(state, data) {
    state.partyBag = data;
  }
};