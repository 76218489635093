import { GetterTree } from "vuex";
import { State } from './state';

export const getters: GetterTree<State, any> = {
  filterSuppliers: (state: any) => () => {
    return state.suppliers.filter((sup: any) => {
      // Check if a category is selected
      if (state.searchbar.category > 0) {
        let found = false;
        sup.categories.map((cat: any) => {
          if (cat.id === state.searchbar.category) {
            found = true;
          }
        })
        if (found) {
          return true
        }
      } else { // Return true if not category is selected
        return true
      }
    }).filter((sup: any) => {
      // Check if a text is entered
      if (state.searchbar.text !== "") {
        if (sup.business_name.toLowerCase().search(state.searchbar.text.toLowerCase()) !== -1) {
          return true;
        } else {
          return false;
        }
      } else { // Return true if no text entered
        return true;
      }
    });
  },
  getPartyBag: (state: any) => () => {
    state.partyBag.suppliers = state.partyBag.suppliers.map((mySup: any) => {
      return {
        ...state.suppliers.find((sup: any) => {
          return sup.id === mySup.id;
        }), ...mySup
      }
    });
    return state.partyBag;
  }
}