<template>
  <ion-footer class="ion-no-border white-bg" style="margin-top: 5rem">
    <ion-grid>
      <ion-row
        class="
          ion-justify-content-start ion-align-items-start ion-padding ion-footer
        "
      >
        <ion-col sizeXl="1" sizeLg="0" class="lg-none"></ion-col>
        <ion-col class="ion-padding" sizeXs="12" sizeMd="3" sizeXl="2">
          <img class="logo" src="../assets/svg/pp_logo.svg" />
          <ion-text color="light" class="mobile-none">
            Copyright &copy; Party Place 2021
            <br />All Rights Reserved</ion-text
          >
        </ion-col>
        <ion-col sizeXs="6" sizeMd="4" sizeXl="2" class="pp-menu">
          <ion-text>
            <h3><strong>About Party Place</strong></h3>

            <router-link to="/about"><a href="">About Us</a></router-link>
            <a href="/privacy-policy-customer" target="_blank">
              Privacy Policy - Customers & Corporate
            </a>
            <a href="/privacy-policy-supplier" target="_blank">
              Privacy Policy - Suppliers
            </a>
            <a href="/terms-of-business-customer" target="_blank">
              Terms of Business - Customers & Corporate
            </a>
            <a href="/terms-of-business-supplier" target="_blank">
              Terms of Business - Suppliers
            </a>
          </ion-text>
        </ion-col>
        <ion-col sizeXs="5" sizeMd="3" sizeXl="2" class="pp-menu">
          <ion-text>
            <h3><strong>Get Involved</strong></h3>
            <router-link to="/suppliers"
              ><a href=""> Plan your party </a></router-link
            >
            <a href="https://suppliers.partyplace.je" target="_blank">
              Add your business
            </a>
          </ion-text>
        </ion-col>
        <ion-col sizeXs="12" sizeMd="12" sizeLg="3" sizeXl="4">
          <ion-text
            ><h3><strong>Newsletter</strong></h3></ion-text
          >
          <ion-text color="medium">
            <p>Receive special offers and get our latest updates.</p>
          </ion-text>

          <form
            action="https://partyplace.us5.list-manage.com/subscribe/post?u=2778513bb239054090a523212&amp;id=850348e216"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            class="validate"
            target="_blank"
            novalidate
          >
            <div class="pp-flex-me footer-sub">
              <ion-input
                type="email"
                value=""
                name="EMAIL"
                class="required email email-input-sub"
                id="mce-EMAIL"
                placeholder="Enter Your Email Address"
              />
              <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
              <div style="position: absolute; left: -5000px" aria-hidden="true">
                <input
                  type="text"
                  name="b_2778513bb239054090a523212_850348e216"
                  tabindex="-1"
                  value=""
                />
              </div>
              <ion-button
                expand="full"
                color="primary"
                type="submit"
                value="Subscribe"
                name="subscribe"
                id="mc-embedded-subscribe"
                class="w-text"
                >Subscribe</ion-button
              >
            </div>
            <div id="mce-responses" class="clear">
              <div
                class="response"
                id="mce-error-response"
                style="display: none"
              ></div>
              <div
                class="response"
                id="mce-success-response"
                style="display: none"
              ></div>
            </div>
          </form>

          <!-- <div class="pp-flex-me footer-sub">
            <ion-input
              class="email-input-sub"
              type="email"
              placeholder="Enter Your Email Address"
            >
            </ion-input>
            <ion-button class="w-text" expand="full" color="primary"
              >Subscribe</ion-button
            >
          </div> -->
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-grid>
      <ion-row class="ion-padding">
        <ion-col sizeXl="1" sizeLg="0" class="lg-none"></ion-col>
        <ion-col sizeXl="2" sizeLg="3.3" sizeXs="12">
          <div class="footer-social-container">
            <a href="https://www.instagram.com/partyplace.je/" target="_blank">
              <img src="../assets/svg/footer-i.svg" />
            </a>

            <a
              href="https://www.linkedin.com/company/80315765/"
              target="_blank"
            >
              <img src="../assets/svg/linkedin-logo.svg" />
            </a>

            <a href="https://www.facebook.com/PartyPlace.je" target="_blank">
              <img src="../assets/svg/footer-fb.svg" />
            </a>
          </div>
        </ion-col>
        <ion-col sizeXl="2" sizeLg="6" sizeXs="12">
          <ion-text color="light" class="desktop-none copyright-mobile">
            Copyright &copy; Party Place 2021
            <br />All Rights Reserved
          </ion-text>
        </ion-col>
        <ion-col sizeXl="6" sizeLg="0" class="lg-none"></ion-col>
      </ion-row>
    </ion-grid>
  </ion-footer>
</template>

<script>
import {
  IonFooter,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonInput,
  IonButton,
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Footer",
  components: {
    IonFooter,
    IonGrid,
    IonRow,
    IonCol,
    IonText,
    IonInput,
    IonButton,
  },
});
</script>

<style scoped>
.footer-social-container img {
  height: 20px;
  width: 20px;
}
@media (max-width: 1000px) {
  ion-footer {
    font-size: 15px;
  }
}

@media (max-width: 768px) {
  .copyright-mobile {
    margin-top: 1rem;
  }
}
</style>