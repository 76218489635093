import { createStore, useStore as baseUseStore } from 'vuex';
import createPersistedState from "vuex-persistedstate"
import { state, key } from './state';
import { mutations } from './mutations';
import { actions } from './actions';
import { getters } from './getters';

const plugins = [createPersistedState()]

export const store = createStore({ state, mutations, actions, getters, plugins });

// our own useStore function for types
export function useStore() {
  return baseUseStore(key);
}