
import {
  IonContent,
  IonRow,
  IonGrid,
  IonText,
  IonCheckbox,
  IonList,
  modalController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { useStore } from "../store";

export default defineComponent({
  name: "Basket",
  components: {
    IonContent,
    IonRow,
    IonGrid,
    IonText,
    IonCheckbox,
    IonList,
  },
  data: () => {
    return {
      store: useStore(),
    };
  },
  computed: {
    getPartyBag(): {} {
      return this.store.getters.getPartyBag();
    },
  },
  methods: {
    removeFromPartyBag(id: number) {
      this.store.dispatch("removeFromPartyBag", id);
    },
    buildParty() {
      this.closeModal().then(() => {
        this.$router.push({ path: "/build-party" });
      });
    },
    closeModal() {
      return modalController.dismiss();
    },
  },
});
