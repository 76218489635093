import { InjectionKey } from 'vue';
import { Store } from 'vuex';

// interfaces for our State
export type State = {
  apiUrl: string;
  user: any;
  registerForm: {};
  searchbar: {
    category: number;
    text: string;
  };
  categories: [];
  suppliers: [];
  partyBag: {
    partyName: string;
    dateTime: string;
    guests: number;
    children: number;
    locationAddress: string;
    locationCoords: {
      lat: string;
      lon: string;
    };
    image: string;
    userId: number;
    bookingStatus: string;
    additionalRequirements: string;
    suppliers: {}[];
  };
}

export const key: InjectionKey<Store<State>> = Symbol();

export const state: State = {
  apiUrl: process.env.VUE_APP_API_URL,
  user: null,
  registerForm: {
    name: "",
    email: "",
    phone: "",
    dob: "",
    password: "",
    facebookId: "",
    googleId: "",
    profileImage: "",
  },
  searchbar: {
    category: 0,
    text: ""
  },
  categories: [],
  suppliers: [],
  partyBag: {
    partyName: "",
    dateTime: "",
    guests: 0,
    children: 0,
    locationAddress: "",
    locationCoords: {
      lat: "",
      lon: "",
    },
    image: "",
    userId: 0,
    bookingStatus: "",
    additionalRequirements: "",
    suppliers: []
  }
};