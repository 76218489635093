
import {
  IonHeader,
  IonCol,
  IonGrid,
  IonRow,
  IonButton,
  modalController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import Basket from "./Basket.vue";
import SearchBar from "./Searchbar.vue";
import { useStore } from "../store";

export default defineComponent({
  name: "Header",
  components: {
    IonHeader,
    IonCol,
    IonGrid,
    IonRow,
    IonButton,
    SearchBar,
  },
  data: () => {
    return {
      store: useStore(),
    };
  },
  computed: {
    user(): {} {
      return this.store.state.user;
    },
    bagCount(): number {
      return this.store.state.partyBag.suppliers.length;
    },
    showBasket(): boolean {
      const path = this.$route.fullPath;
      if (path.includes("build-party") || path.includes("checkout")) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    async openBasket() {
      // console.log("opening");
      const modal = await modalController.create({
        component: Basket,
        cssClass: "addtobasket",
        componentProps: {
          title: "Add To Party",
        },
        swipeToClose: true,
      });
      return modal.present().then(() => {
        // console.log("opened");
      });
    },
  },
});
