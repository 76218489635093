
import { IonApp } from "@ionic/vue";
import { defineComponent } from "vue";
import { useStore } from "./store";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
  },
  data: () => {
    return {
      store: useStore(),
    };
  },
  mounted() {
    this.store.dispatch("checkAuth");
    this.store.dispatch("getSupplierCategories");
    this.store.dispatch("getSuppliers");
  },
});
