<template>
  <div id="searchbar-container">
    <ion-item>
      <ion-select
        interface="popover"
        ok-text="Select"
        cancel-text="Dismiss"
        placeholder="Select Category"
        v-model="searchbar.category"
      >
        <ion-select-option :value="0"> All Categories</ion-select-option>
        <ion-select-option
          v-for="cat in categories"
          :value="cat.id"
          :key="cat.id"
        >
          {{ cat.name }}
        </ion-select-option>
      </ion-select>
    </ion-item>

    <ion-input type="text" placeholder="Search..." v-model="searchbar.text">
    </ion-input>
    <router-link to="/suppliers">
      <ion-button color="primary" expand="full">
        <img src="../assets/svg/searchicon.svg" />
      </ion-button>
    </router-link>
  </div>
</template>

<script>
import {
  IonSelect,
  IonSelectOption,
  IonInput,
  IonButton,
  IonItem,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { useStore } from "../store";

export default defineComponent({
  name: "SearchBar",
  components: { IonSelect, IonSelectOption, IonInput, IonButton, IonItem },
  data: () => {
    return {
      store: useStore(),
    };
  },
  computed: {
    searchbar() {
      return this.store.state.searchbar;
    },
    categories() {
      return this.store.state.categories;
    },
  },
});
</script>

<style scoped>
#searchbar-container {
  display: flex;
  width: 100%;
  margin: auto 0;
  border: 1px solid var(--ion-color-light);
  border-radius: 5px;
  max-height: 50px;
}

#searchbar-container ion-button {
  margin: 0;
  flex-shrink: 0;
  min-height: 100%;
}

#searchbar-container ion-button img {
  margin: auto;
}

#searchbar-container ion-button::part(native) {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  min-height: 48px;
  max-height: 48px;
  border: 1px solid var(--ion-color-secondary);
}

#searchbar-container ion-item {
  --border-width: 0px;
}

#searchbar-container ion-item ion-select {
  min-height: 48px;
}

#searchbar-container ion-item::part(native) {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

@media screen and (max-width: 768px) {
  #searchbar-container,
  #searchbar-container ion-item ion-select {
    max-height: 40px;
    --min-height: 38px;
    --max-height: 38px;
    --padding-top: 0;
  }

  #searchbar-container ion-button::part(native) {
    min-height: 38px;
  }
}
</style>
